<template>
  <div class="loading-wrap">
    <span class="loading-spinner"></span>
  </div>
</template>

<style lang="sass" scoped>
.loading-wrap
  padding: 80px 0

.loading-spinner
  display: block
  height: 24px
  width: 24px
  margin: 14px auto 0 auto
  position: relative
  -webkit-animation: rotation 0.6s infinite linear
  -moz-animation: rotation 0.6s infinite linear
  -o-animation: rotation 0.6s infinite linear
  animation: rotation 0.6s infinite linear
  border-left: 3px solid #314053
  border-right: 3px solid #f1f1f1
  border-bottom: 3px solid #f1f1f1
  border-top: 3px solid #f1f1f1
  border-radius: 100%
</style>
