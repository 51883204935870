/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { serviceWorkerEnabled } from './util/service_worker_utils'

if (serviceWorkerEnabled()) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    updateViaCache: 'none',
  })
}
