import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'

import { getAdToken } from '../util/active_directory_helper'
import router from '@/router'
import { t } from '@/i18n'

axios.defaults.withCredentials = true

export const baseUrl = process.env.VUE_APP_BASE_API

const baseAuthHeader = async () => {
  const token = await getAdToken()
  return token ? { Authorization: `Bearer ${token}` } : null
}

const handleAPIErrors = (errors, status) => {
  if (status === 401 && router.currentRoute.value.meta.skipAuth) return

  errors?.forEach(value =>
    notify({
      group: 'alert',
      type: 'error',
      text: value,
    }),
  )
}

export const get = async ({ path, params }) => {
  try {
    const response = await axios.get(`${baseUrl}/v1/${path}`, {
      headers: await baseAuthHeader(),
      ...(!!params && { params: { ...params } }),
    })
    return { success: true, data: response.data }
  } catch (error) {
    const errors = error.response?.data.errors
    const status = error.response?.status
    handleAPIErrors(errors, status)
    return { success: false, errors, status }
  }
}

export const patch = async ({ path, params, hideNotify }) => {
  try {
    const response = await axios.patch(
      `${baseUrl}/v1/${path}`,
      { ...params },
      { headers: await baseAuthHeader() },
    )
    if (!hideNotify) {
      notify({
        group: 'alert',
        type: 'success',
        title: t('meta.successfullySaved'),
      })
    }
    return { success: true, data: response.data }
  } catch (error) {
    const errors = error.response?.data.errors
    const status = error.response?.status
    handleAPIErrors(errors, status)
    return { success: false, errors, status }
  }
}

export const post = async ({ path, params, hideNotify }) => {
  try {
    const response = await axios.post(
      `${baseUrl}/v1/${path}`,
      { ...params },
      { headers: await baseAuthHeader() },
    )
    if (!hideNotify) {
      notify({
        group: 'alert',
        type: 'success',
        title: t('meta.successfullySaved'),
      })
    }
    return { success: true, data: response.data, headers: response.headers }
  } catch (error) {
    const errors = error.response?.data.errors
    const status = error.response?.status
    handleAPIErrors(errors, status)
    return { success: false, errors, status }
  }
}

export const remove = async ({ path, hideNotify }) => {
  try {
    const response = await axios.delete(`${baseUrl}/v1/${path}`, {
      headers: await baseAuthHeader(),
    })
    if (!hideNotify) {
      notify({
        group: 'alert',
        type: 'success',
        title: t('meta.successfullyDeleted'),
      })
    }
    return { success: true, data: response.data }
  } catch (error) {
    const errors = error.response?.data.errors
    const status = error.response?.status
    handleAPIErrors(errors, status)
    return { success: false, errors, status }
  }
}
