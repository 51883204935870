import { createI18n } from 'vue-i18n'
import deTranslations from '@/locales/de.json'
import enTranslations from '@/locales/en.json'
import plTranslations from '@/locales/pl.json'
import uaTranslations from '@/locales/ua.json'

export const LOCALES = {
  DE: 'de',
  EN: 'en',
  PL: 'pl',
  UA: 'ua',
}

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: LOCALES.DE,
  fallBackLocale: LOCALES.DE,
  messages: {
    de: deTranslations,
    en: enTranslations,
    ua: uaTranslations,
    pl: plTranslations,
  },
})

export const t = i18n.global.t

export const availableLocales = i18n.global.availableLocales
