import { createApp } from 'vue'
import { i18n } from './i18n'
import App from './App.vue'
import router from './router'
import Notifications from '@kyvg/vue3-notification'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@fontsource/source-sans-pro'
import 'material-icons/iconfont/filled.css'

import LoadingSpinner from './components/LoadingSpinner'
import './registerServiceWorker'

const app = createApp(App)

const unsupportedSentryEnvironments = ['development', 'test']
if (!unsupportedSentryEnvironments.includes(process.env.NODE_ENV)) {
  Sentry.init({
    app,
    dsn: 'https://016683d04f264a1ea49842253ef9cde3@o1082260.ingest.sentry.io/4504174362492928',
    environment: 'staging',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['tour.staging.binity.io', 'tour.binity.io', /^\//],
      }),
    ],
    tracesSampleRate: 0.2,
  })
}

app.component('LoadingSpinner', LoadingSpinner)
app.use(router)
app.use(Notifications)
app.use(i18n)
app.mount('#app')

// we need this setting is required to use injection till Vue 3.3
// https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity:~:text=Temporary%20Config%20Required
app.config.unwrapInjectedRef = true
