export const serviceWorkerEnabled = () => {
  return (
    'serviceWorker' in navigator &&
    (process.env.NODE_ENV === 'production' ||
      process.env.NODE_ENV === 'staging')
  )
}

export const addSwCacheListener = callback => {
  if (!serviceWorkerEnabled()) return

  navigator.serviceWorker.addEventListener('message', async event => {
    if (event.data?.type === 'CACHE_UPDATE') {
      callback()
    }
  })

  if (!navigator.serviceWorker.controller) {
    setTimeout(() => {
      location.reload()
    }, 800)
  }
}
