<template>
  <div>
    <notifications
      group="alert"
      position="top center"
      classes="vue-notification alert-notification"
    />

    <div v-if="isLoggedIn">
      <header
        class="app-header"
        :class="{ 'with-box-shadow': $route.path !== '/routes' }"
      >
        <ul class="nav nav-header">
          <li>
            <router-link to="/routes">
              <span class="material-icons">map</span>
              <span>{{ $t('navigation.routes') }}</span>
            </router-link>
          </li>
          <li>
            <router-link to="/containers">
              <span class="material-icons">delete</span>
              <span>{{ $t('navigation.containers') }}</span>
            </router-link>
          </li>
        </ul>
        <ul class="nav nav-meta">
          <li v-if="!isOnline">
            <router-link to="">
              <span class="material-icons">signal_cellular_off</span>
            </router-link>
          </li>
          <li>
            <router-link to="/info">
              <span class="material-icons">info</span>
            </router-link>
          </li>
          <li>
            <a @click="logout"
              ><span class="material-icons">exit_to_app</span
              ><span class="logout-text">{{ $t('navigation.logout') }}</span></a
            >
          </li>
        </ul>
      </header>

      <main class="app-body">
        <router-view @onsync="onSyncing" />
      </main>

      <div class="syncing" v-if="syncing.length > 0">
        <span class="material-icons">cloud_sync</span>
        <div>{{ syncing.length }}</div>
      </div>
    </div>

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapSettings } from './util/settings'
import { serviceWorkerEnabled } from './util/service_worker_utils'
import { getUser } from './api/user'
import { signOut } from './api/auth'
import axios from 'axios'
import { baseUrl } from './api/base'
import { computed } from 'vue'
import { adLogout } from './util/active_directory_helper'
import { i18n } from '@/i18n'

export default {
  data() {
    return {
      user: null,
      isOnline: true,
      syncing: [],
    }
  },
  provide() {
    return {
      isOnline: computed(() => this.isOnline),
    }
  },
  computed: {
    ...mapSettings(['userLanguage']),
    isLoggedIn() {
      return this.user != null
    },
  },
  methods: {
    async loadUser() {
      const { success, data, status } = await getUser()
      // also unsets it in case the login is not valid anymore. Used for the "logged in" check
      this.user = data?.user

      if (success) {
        this.user = data.user
        this.userLanguage = this.user.language
        document.documentElement.lang = this.user.language
        i18n.global.locale.value = this.user.language
      } else {
        if (status === 401) {
          this.$router.push('/auth/sign_in')
        }
      }
    },
    async logout() {
      await adLogout()
      await signOut()

      this.user = null
      this.$router.push('/auth/sign_in')
    },
    onSyncing(syncingUuid) {
      this.syncing.push(syncingUuid)
      this.syncing = [...new Set(this.syncing)]
      localStorage.setItem('requests_to_sync', JSON.stringify(this.syncing))
    },
    backgroundSyncListener() {
      if (!serviceWorkerEnabled()) return

      navigator.serviceWorker.addEventListener('message', async event => {
        if (event.data?.type === 'SYNCED_REQUESTS') {
          this.syncing = []
          localStorage.removeItem('requests_to_sync')
          setTimeout(() => {
            location.reload()
          }, 800)
        }
        if (event.data?.type === 'FETCH_ERROR') {
          this.syncing = []
          localStorage.removeItem('requests_to_sync')
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Error while syncing',
          })
        }
      })
    },
    async checkNetwork() {
      try {
        const response = await axios.get(baseUrl)
        if (response.status == 200 && !this.isOnline) {
          this.isOnline = true
          setTimeout(() => {
            location.reload()
          }, 800)
        }
      } catch (error) {
        if (error.toString().toLowerCase().includes('network')) {
          this.isOnline = false
        }
      }
    },
  },
  mounted() {
    this.loadUser()

    window.addEventListener('online', () => (this.isOnline = true))
    window.addEventListener('offline', () => (this.isOnline = false))
    this.backgroundSyncListener()
    this.checkNetwork()

    setInterval(() => this.checkNetwork(), 10000)

    this.syncing = JSON.parse(localStorage.getItem('requests_to_sync')) || []
  },
  watch: {
    $route(to, from) {
      if (to.path === '/routes') {
        if (from.path === '/auth/sign_in') {
          location.reload()
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import 'assets/css/skadi.css';
@import 'assets/css/style.scss';
</style>

<style lang="scss" scoped>
.syncing {
  position: fixed;
  height: 4rem;
  width: 5rem;
  background-color: #314053;
  bottom: 0;
  right: 0;
  border-top-left-radius: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 2px;
  }
}
</style>
