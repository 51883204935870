import { post, remove } from './base'

export const signIn = ({ email, password }) =>
  post({
    path: 'auth/sign_in',
    params: { email, password },
    hideNotify: true,
  })

export const signOut = () =>
  remove({ path: '/auth/sign_out', hideNotify: true })
