import { PublicClientApplication } from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AAD_CLIENT_ID,
    authority: process.env.VUE_APP_AAD_AUTHORITY,
    knownAuthorities: [process.env.VUE_APP_AAD_KNOWN_AUTHORITY],
    redirectUri: process.env.VUE_APP_AAD_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: process.env.VUE_APP_AAD_POST_LOGOUT_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
  },
}

const publicClientApplication = new PublicClientApplication(msalConfig)

const makeAccesstokenRequest = () => {
  const account = publicClientApplication.getAllAccounts()[0]
  return {
    account,
    forceRefresh: false,
    scopes: [process.env.VUE_APP_AAD_SCOPE],
  }
}

// This method is using a popup to get the token, which only works from direct user interaction since the browser would
// block the popup elsewise. Therefore this function should only be called directly from the login button.
export const loginWithAd = async () => {
  try {
    return await publicClientApplication.acquireTokenPopup(
      makeAccesstokenRequest(),
    )
  } catch (error) {
    console.log(error)
    return false
  }
}

// This uses the silent refresh mechanism to get a token to be used by the api. The silent refresh is caching and
// validating the tokens lifetime for us
// It also only works if there is a user account already present, so we check this first.
export const getAdToken = async () => {
  const accessTokenRequest = makeAccesstokenRequest()
  // can't if there is no account
  if (accessTokenRequest.account == null) return null

  try {
    // try silent refresh
    return (
      await publicClientApplication.acquireTokenSilent(accessTokenRequest)
    ).accessToken
  } catch (error) {
    console.log(error)
    return null
  }
}

export const adLogout = async () => {
  const accessTokenRequest = makeAccesstokenRequest()
  // can't if there is no account
  if (accessTokenRequest.account == null) return null

  return await publicClientApplication.logoutPopup(msalConfig)
}
