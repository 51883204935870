import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/routes',
  },

  {
    path: '/routes',
    name: 'routes',
    component: () => import('../views/routes/index.vue'),
  },
  {
    path: '/routes/:uuid',
    name: 'route',
    component: () => import('../views/routes/show.vue'),
  },
  {
    path: '/routes/:uuid/stops/:stop_uuid',
    name: 'route_stops',
    component: () => import('../views/routes/stops/show.vue'),
  },
  {
    path: '/routes/:uuid/stops/:stop_uuid/containers/:container_uuid',
    name: 'route_stop_container',
    component: () => import('../views/routes/stops/containers/show.vue'),
  },

  {
    path: '/containers',
    name: 'containers',
    component: () => import('../views/containers/index.vue'),
  },
  {
    path: '/containers/:uuid',
    name: 'container',
    component: () => import('../views/containers/show.vue'),
  },

  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info/index.vue'),
  },

  {
    path: '/auth/sign_in',
    name: 'auth_sign_in',
    component: () => import('../views/auth/sign_in.vue'),
    meta: { skipAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
