import { reactive, computed } from 'vue'

import { LOCALES } from '@/i18n'

const STORAGE_KEY = 'settings'

// All settings used in the app should have a default set here.
const defaults = {
  showTourOnMaps: true,
  showNavigation: true,
  favoriteNavigationApp: null,
  deviceId: null,
  userLanguage: LOCALES.DE,
  showMaps: true,
}

let settings = reactive(defaults)
try {
  // saved in browser
  const settingsString = localStorage.getItem(STORAGE_KEY)
  settings = reactive({ ...settings, ...JSON.parse(settingsString) })
} catch {
  // In case something goes wrong with the json parsing we just use the defaults
}

const mapSetting = key => ({
  get() {
    return settings[key]
  },
  set(value) {
    setSetting(key, value)
  },
})

const setSetting = (key, value) => {
  settings[key] = value

  localStorage.setItem(STORAGE_KEY, JSON.stringify(settings))
}

// This is how settings should be used exclusively. Map the settings you want to use in the component in the computed properties and they will be usable with a getter and setter.
//
// ```
// // old school
//
// computed: { ...mapSettings(['some']) },
// methods: {
//   updateSome() {
//     // Will update reactively and be stored in localStorage
//     this.some = 'thing'
//   }
// }
//
// // new school
//
// const { favoriteNavigationApp } = mapSettingsComputed(['favoriteNavigationApp'])
//
// ```
export const mapSettings = keys => {
  keys.forEach(key => {
    if (!Object.keys(defaults).includes(key)) {
      console.warn(
        `Tried to map setting "${key}", which does not have a default set.`,
      )
    }
  })

  return Object.fromEntries(keys.map(key => [key, mapSetting(key)]))
}

export const mapSettingsComputed = keys => {
  return Object.fromEntries(
    Object.entries(mapSettings(keys)).map(([key, setting]) => [
      key,
      computed(setting),
    ]),
  )
}
